import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import Form from './Form'
import {device, colors} from './styles/GlobalStyles'

import Envelope from "../assets/images/envelope.svg"
import Mobile from "../assets/images/mobile-phone.svg"
import Facebook from "../assets/images/facebook.svg"
import Twitter from "../assets/images/twitter.svg"
import Linkedin from "../assets/images/linkedin.svg"

const GetInTouch = () => {
  return (
    <Container>
      <ContentWrapper>
        <EnvelopeImg src={Envelope} />
        <Heading>
          Get in touch
        </Heading>
        <Description>
          Let’s talk shop & see how we can transform your business.
        </Description>
        <RowWrapper>
          <NavLink href='mailto:hello@bluetel.co.uk'>
            <EnvelopeSocial src={Envelope} />
            hello@bluetel.co.uk</NavLink>
          <NavLink href='tel:+44121-643-7469'>
            <PhoneSocial src={Mobile} />
            +44 (0)121 6437469</NavLink>
        </RowWrapper>
        <RowWrapper>
          <MediaNavLink href='https://www.linkedin.com/company/bluetel-solutions-ltd'>
            <LinkedInImg src={Linkedin} />
            LinkedIn
          </MediaNavLink>
        </RowWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default GetInTouch

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;    
  justify-content: center;
  align-items: center; 
  padding: 31.5px 0px 0px 0px; 

  @media ${device.laptop} {
    padding: 41px 0px 0px 0px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;    
  justify-content: center;
  align-items: center; 
  margin: 30px;

  @media ${device.laptop} {
    flex-direction: row;  
  }
`

const EnvelopeImg = styled.img`
  width: 37.5px;
  height: 25px;
  margin: 0px;

  @media ${device.laptop} {
    width: 45px;
    height: 30px;
  }
`

const EnvelopeSocial = styled.img`
  width: 18.75px;
  height: 12.5px; 
  margin: 0px 16.5px 0px 0px;

  @media ${device.laptop} {
    margin: 0px 17px 0px 0px;
    width: 22.5px;
    height: 15px; 
  }
`

const PhoneSocial = styled.img`
  width: 11.25px;
  height: 18.75px; 
  margin: 0px 20px 0px 0px;

  @media ${device.laptop} {
    width: 13.5px;
    height: 22.5px; 
    margin: 0px 21px 0px 0px;
  }
`

const LinkedInImg = styled.img`
  width: 32px;
  height: 32px; 
  margin: 0px 16px 0px 0px;

  @media ${device.laptop} {
    width: 36px;
    height: 36px; 
    margin: 0px 20px 0px 0px;
  }
`

const TwitterImg = styled.img`
  width: 31.65px;
  height: 23.75px;
  margin: 0px 16.5px 0px 0px;

  @media ${device.laptop} {
    width: 40px;
    height: 30px; 
  }
`

const FacebookImg = styled.img`
  width: 14px;
  height: 25px; 
  margin: 0px 25px 0px 0px;

  @media ${device.laptop} {
    width: 17.5px;
    height: 31px; 
    margin: 0px 27px 0px 0px;
  }
`

const Heading = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 118%;
  color: ${colors.white};
  margin: 15.5px 0px 8px 0px;

  @media ${device.laptop} {
    font-size: 56px;
    line-height: 140%;
    margin: 9px 0px 4px 0px;
  }
`

const Description = styled.p`
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: ${colors.grey};
  margin: 0px 0px 20px 0px;

  @media ${device.laptop} {
    width: 760px;
    font-size: 24px;
    margin: 0px 0px 16px 0px;
  }
`

const NavLink = styled.a`
  display: flex;
  color: ${colors.green};
  text-align: center;
  align-items: center;  
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-decoration-line: underline;

  :first-of-type{
    margin: 0px 0px 16px 0px;
  }

  @media ${device.laptop} {
    font-size: 24px;
    margin: 0px 37px 0px 0px;

    :first-of-type{
      margin: 0px 37px 0px 0px;
    }
  }
`

const MediaNavLink = styled.a`
  display: flex;
  color: ${colors.green};
  text-align: center;
  align-items: center;  
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-decoration: none;

  :first-of-type, :nth-child(2){
    margin: 0px 0px 23px 0px;
  }

  :nth-child(3){
    margin: 0px 0px 83.5px 0px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    margin: 0px 37px 50px 0px;

    :first-of-type, :nth-child(2), :nth-child(3){
      margin: 0px 37px 50px 0px;
    }
  }
`