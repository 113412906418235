import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GetInTouch from '../components/GetInTouch'
import { ContactPageQueryQuery } from '../../types/graphql-types' // eslint-disable-line import/no-unresolved

type Props = {
  data: ContactPageQueryQuery
}

const ContactLandingPage: React.FC<Props> = ({ data }: Props) => {

  return (
    <Layout>
      <SEO title="Contact" />
        <GetInTouch/>
    </Layout>
  )
}

// export const query = graphql`
//   query ContactPageQuery {
//     allContentfulLandingPage(filter: { title: { eq: "Contact" } }) {
//       edges {
//         node {
//           id
//           title
//           body {
//             json
//           }
//         }
//       }
//     }
//   }
// `

export default ContactLandingPage

